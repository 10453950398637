/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #d43a3a;
  --ion-color-primary-rgb: 212, 58, 58;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b53232;
  --ion-color-primary-tint: #e04747;


  /** secondary **/
  /** secondary **/
  --ion-color-secondary: #fbecec;
  --ion-color-secondary-rgb: 251, 236, 236;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e5d8d8;
  --ion-color-secondary-tint: #fffcfc;


  /** tertiary **/
  --ion-color-tertiary: #4b4b4b;
  --ion-color-tertiary-rgb: 75, 75, 75;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #424242;
  --ion-color-tertiary-tint: #5e5e5e;


  /** success **/
  --ion-color-success: #5fab00;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  /** danger **/
  --ion-color-danger: #dc2626;
  --ion-color-danger-rgb: 220, 38, 38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c42424;
  --ion-color-danger-tint: #e03a3a;


  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  --ion-color-info: #3481D5;
  --ion-color-info-rgb: 244, 245, 248;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #d7d8da;
  --ion-color-info-tint: #f5f6f9;

  ion-tab-button.tab-selected {
    ion-icon, ion-label {
      color: var(--ion-color-primary) !important;
      --ion-color-base: var(--ion-color-primary) !important;
    }
  }
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}




input {
  -webkit-appearance: none !important;
  appearance: none;
}

select {
  -webkit-appearance: none !important;
  appearance: none;
}

textarea {
  -webkit-appearance: none !important;
  appearance: none;
}

a {
  -webkit-appearance: none !important;
  appearance: none;
  color:var(--ui-spaceblack);
}



// ion-app {
//   margin-bottom: env(safe-area-inset-bottom) !important;
//   /* Status bar height on iOS 11+ */
// }

// html.plt-mobile ion-app {
//   margin-bottom: env(safe-area-inset-bottom) !important;
// }